import React, { useEffect, useRef } from 'react';
import doorJson from '../../assets/json/animationDoor.json';
import { Player } from '@lottiefiles/react-lottie-player';

const DoorAnimate = () => {
  const playerRef = useRef();
  const wrapperRef = useRef();

  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.5, // When 50% of the target is in view
    };
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        playerRef.current.play();
        return;
      }
    }, options);
    observer.observe(wrapperRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div className="w-full" ref={wrapperRef}>
      <Player
        className="w-full"
        autoplay={false}
        loop={false}
        src={doorJson}
        style={{ width: '100%' }}
        ref={playerRef}
        keepLastFrame={true}
      ></Player>
    </div>
  );
};

export default DoorAnimate;
