/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import { AiOutlineClose, AiOutlineMenu, AiFillCaretDown } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo.svg';
import clsx from 'clsx';
import { NAVBAR, ROUTE } from '../../constants';
import classes from './index.module.scss';
import ButtonCustom from '../button/Button';
import { Button, Drawer, Popover } from 'antd';
import { SUPPORTED_LANGUAGE, TRANSLATE_KEY } from '../../languages';
import { Link } from 'react-router-dom';
import useWindowResize from '../../hooks/useWindowResize';
import useScrollDirection from '../../hooks/useScrollDirection';

const defaultLanguage = SUPPORTED_LANGUAGE[0];

const Navbar = () => {
  const { i18n, t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage);
  const { isDesktop } = useWindowResize();
  const scrollDirection = useScrollDirection();

  useEffect(() => {
    const language = i18n.language.split('-')[0];
    const newLanguage =
      SUPPORTED_LANGUAGE.find((i) => i.code === language) || defaultLanguage;
    setCurrentLanguage(newLanguage);
  }, [i18n.language]);

  useEffect(() => {
    if (isDesktop && menuOpen) {
      setMenuOpen(false);
    }
  }, [isDesktop, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen(!menuOpen);
  };

  const goToSection = (event) => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  };

  const renderNavItem = () => {
    return (
      <ul
        className={clsx(
          'flex font-medium',
          menuOpen ? 'flex-col text-center' : 'gap-10',
        )}
      >
        {NAVBAR.map(({ title, sectionId }, index) => {
          return (
            <Link
              key={sectionId}
              className={clsx(
                'relative group',
                menuOpen && 'text-xl mx-10 py-5 text-center bg-bgGray',
                menuOpen && index === 0 ? 'mt-4 mb-2' : 'my-2',
              )}
              onClick={goToSection}
              to={`${ROUTE.HOME}#${sectionId}`}
            >
              <div
                href="#"
                className={clsx(
                  'group-hover:text-p500 transition-all ease-linear',
                )}
              >
                {t(title)}
              </div>
              <div className="absolute bg-p500 h-[2px] duration-300 w-0 group-hover:w-full bottom-0 left-0 transition-all ease-linear"></div>
            </Link>
          );
        })}

        {!isDesktop && (
          <li className="px-5 text-center pt-2">
            <ButtonCustom
              className="w-full my-1"
              type="secondary"
              text={t(TRANSLATE_KEY.request_a_demo)}
            />
          </li>
        )}
      </ul>
    );
  };

  return (
    <header
      className={clsx(
        ' shadow-md fixed top-0 w-full bg-white z-10',
        'transform transition-transform duration-1500 ease-in-out',
        scrollDirection === 'down' ? '-translate-y-full' : 'translate-y-0',
      )}
    >
      <div
        className={clsx(
          'm-auto md:h-[72px] lg:h-[90px] w-full py-5 max-w-7xl',
          !isDesktop ? classes.header : 'justify-between',
          'lg:px-10 px-5 xl:px-0',
        )}
      >
        <div className={clsx(classes.header__content)}>
          <div className={clsx(classes.header__content__toggle)}>
            <AiOutlineMenu
              className="text-bgMain hover:text-p500 cursor-pointer"
              onClick={menuToggleHandler}
            />
          </div>
          {/* Logo  */}
          <Link
            to={ROUTE.HOME}
            className={clsx(
              classes.header__content__logo,
              isDesktop && 'mr-12',
            )}
          >
            <img src={logo} alt=""></img>
          </Link>
          {/* NavBar */}
          <nav className={clsx(classes.header__content__nav)}>
            {menuOpen ? (
              <Drawer
                styles={{
                  header: { padding: '1.25rem 1.25rem' },
                  body: { padding: 0 },
                }}
                closable={false}
                title={
                  <div className="flex items-center">
                    <AiOutlineClose
                      className="text-black hover:text-p500 text-2xl cursor-pointer"
                      onClick={menuToggleHandler}
                    />
                    <div className="flex-1 text-center">
                      <Link
                        onClick={goToSection}
                        to={ROUTE.HOME}
                        className={clsx(classes.header__content__logo)}
                      >
                        <img src={logo} alt=""></img>
                      </Link>
                    </div>
                  </div>
                }
                placement="left"
                onClose={() => setMenuOpen(!menuOpen)}
                open={menuOpen}
                size="large"
                extra={null}
              >
                {renderNavItem()}
              </Drawer>
            ) : (
              renderNavItem()
            )}
          </nav>

          {isDesktop && <div className="flex-1"> </div>}

          <div className="flex items-center justify-center">
            {isDesktop && (
              <>
                <ButtonCustom
                  type="secondary"
                  text={t(TRANSLATE_KEY.request_a_demo)}
                />
                <div className="h-[90px] w-[1px] bg-gray mx-4"></div>
              </>
            )}
            <div className="relative group">
              <div className="center-list pl-2">
                <Popover
                  style={{ padding: 0 }}
                  title={null}
                  placement="bottomRight"
                  content={
                    <div className="flex-col p-0">
                      {SUPPORTED_LANGUAGE.map(({ code }) => (
                        <Button
                          key={code}
                          type="text"
                          className={clsx('w-full uppercase font-semibold')}
                          onClick={() => {
                            i18n.changeLanguage(code);
                          }}
                        >
                          <span
                            className={clsx(
                              code === currentLanguage.code && 'text-p500',
                            )}
                          >
                            {code}
                          </span>
                        </Button>
                      ))}
                    </div>
                  }
                  trigger="hover"
                >
                  <div
                    type="text"
                    className="flex justify-end items-center text-black font-semibold uppercase hover:text-p500 cursor-pointer"
                  >
                    <span>{currentLanguage.code}</span>
                    <AiFillCaretDown />
                  </div>
                </Popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
