import React from 'react';
import logo from '../assets/logo.svg';
import { useTranslation } from 'react-i18next';
import { TRANSLATE_KEY } from '../languages';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-bgMain lg:px-10 px-5 xl:px-0 py-10">
      <div className="flex max-w-7xl m-auto justify-between  text-white">
        <div className="mr-4">
          <img src={logo} alt="" />
          <p className="pt-4 font-semibold">
            {t(TRANSLATE_KEY.crossdocker_is_a_solution_by_bluesky_srl)}
          </p>
        </div>
        <div>
          <p>
            {t(TRANSLATE_KEY.email)}: {'info@crossdocker.it'}
          </p>
          <p>
            {t(TRANSLATE_KEY.phone)}: {'+346 67 86 525'}
          </p>
          <p>
            {t(TRANSLATE_KEY.location)}: {t(TRANSLATE_KEY.location_detail)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
