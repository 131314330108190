import React, { useEffect, useRef, useState } from 'react';
import DoorAnimate from './DoorAnimate';
import { Col, Form, Input, Row, message } from 'antd';
import Button from '../button/Button';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import { TRANSLATE_KEY } from '../../languages';
import './index.style.scss';

message.config({ top: 100 });

const ContactUs = () => {
  const [form] = Form.useForm();
  const ref = useRef();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    emailjs.send('service_2jreizr', 'template_thl9j2q', values).then(
      (result) => {
        setLoading(false);
        message.open({
          type: 'success',
          content: t(TRANSLATE_KEY.send_contact_successfully),
          duration: 3,
        });
      },
      (error) => {
        setLoading(false);
        message.open({
          type: 'error',
          content: t(error.text),
          duration: 3,
        });
      },
    );
  };

  useEffect(() => emailjs.init('xY6fAFBj0reNzzR3J'), []);

  return (
    <div className="cd-contact-container bg-bgGray">
      <div className="relative mx-auto pb-10">
        <div className="w-full h-20 bg-doorColor translate-y-[1px]"></div>
        <DoorAnimate />
        <div className="mt-[-12px] xs:mt-[-42px] sm:mt-[-82px] md:mt-[-128px] xl:mt-[-218px] mb-4">
          <div className="text-center mb-4 md:mb-8 lg:mb-10">
            <div>
              <div className="text-center cd-text-title w-full mb-4">
                <h2>{t(TRANSLATE_KEY.ready_to)}</h2>
                <h2>{t(TRANSLATE_KEY.simplify_your_work)}</h2>
              </div>
              <p className="text-center text-black200 w-4/5 sm:w-2/3 m-auto">
                {t(TRANSLATE_KEY.got_questions_or_ready_to_get_started)}
              </p>
            </div>
          </div>

          <Form
            ref={ref}
            layout="vertical"
            form={form}
            size="large"
            id="contact-us"
            onFinish={onFinish}
            scrollToFirstError
          >
            <div className="flex flex-col mx-auto max-w-7xl px-5 lg:px-10 mt-6">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="name"
                    label={<strong>{t(TRANSLATE_KEY.name)}</strong>}
                    rules={[
                      {
                        required: true,
                        message: t(TRANSLATE_KEY.please_input_your_name),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="email"
                    label={<strong>{t(TRANSLATE_KEY.email)}</strong>}
                    rules={[
                      {
                        type: 'email',
                        message: t(TRANSLATE_KEY.the_input_is_not_valid_email),
                      },
                      {
                        required: true,
                        message: t(TRANSLATE_KEY.please_input_your_email),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="phone"
                    label={<strong>{t(TRANSLATE_KEY.phone_number)}</strong>}
                    rules={[
                      {
                        required: true,
                        message: t(
                          TRANSLATE_KEY.please_input_your_phone_number,
                        ),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Form.Item
                    name="address"
                    label={<strong>{t(TRANSLATE_KEY.address)}</strong>}
                    rules={[
                      {
                        required: true,
                        message: t(TRANSLATE_KEY.please_input_your_address),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="w-full" justify={'center'} align={'center'}>
                <Button
                  loading={loading}
                  htmlType="submit"
                  type="secondary"
                  text={t(TRANSLATE_KEY.send)}
                />
              </Row>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
