import React from 'react';
import Type from './TypeWriter';
import Button from './button/Button';
import { useTranslation } from 'react-i18next';
import { TRANSLATE_KEY } from '../languages';
import heroBanner from '../assets/images/hero-banner.gif';
import heroBannerMobile from '../assets/images/hero-banner-mobile.gif';
import { SECTION_ID } from '../constants';
import goToElement from '../helpers/goToElement';

const Hero = () => {
  const { t } = useTranslation();
  const renderWelcome = () => {
    return (
      <div>
        <h1 className="cd-text-title text-white lg:mb-5 mb-3 whitespace-nowrap">
          {t(TRANSLATE_KEY.welcome_to)}
        </h1>
        <div className="type-container lg:mb-5 mb-3">
          <div>
            <Type />
          </div>
        </div>
        <p className="text-white font-bold text-base">
          {t(TRANSLATE_KEY.home_description_line1)}
        </p>
        <p className="text-white font-bold text-base">
          {t(TRANSLATE_KEY.home_description_line2)}
        </p>
        <div className="md:mt-16 my-4">
          <Button
            onClick={() => goToElement(SECTION_ID.FEATURES)}
            type="secondary"
            text={t(TRANSLATE_KEY.get_started)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="bg-bgMain">
      <div className="max-xl:block xl:hidden pt-[72px] lg:pt-[90px]">
        <div className="cd-section-container">
          <div className="max-w-7xl m-auto flex justify-center">
            <div className="flex flex-col">
              <div>{renderWelcome()}</div>
              <div className="flex justify-center items-center max-md:mt-[-10] md:mt-[-72px]">
                <img src={heroBannerMobile} alt="hero map" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="xl:block max-xl:hidden bg-no-repeat bg-right md:bg-contain lg:bg-cover pt-[72px] lg:pt-[90px]"
        style={{ backgroundImage: `url(${heroBanner})` }}
      >
        <div className="cd-section-container flex justify-start">
          <div className="w-full max-w-7xl m-auto">
            <div className="flex flex-row gap-5">
              <div>{renderWelcome()}</div>
              <div className="flex-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
