import { Player } from '@lottiefiles/react-lottie-player';
import React, { useRef } from 'react';

const LottieIcon = ({ style, src, ...rest }) => {
  const ref = useRef(null);

  return (
    <Player
      {...rest}
      ref={ref}
      autoplay={true}
      loop={true}
      controls={true}
      src={src}
      style={{
        ...style,
      }}
    ></Player>
  );
};

export default LottieIcon;
