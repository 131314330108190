import React, { useRef, useState } from 'react';
import { Navigation, Autoplay } from 'swiper/modules';
import ArrowRight from './button/ArrowRight';
import ArrowLeft from './button/ArrowLeft';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { TRANSLATE_KEY } from '../languages';
import { useTranslation } from 'react-i18next';
import gear from '../assets/json/gear.json';
import support from '../assets/json/support.json';
import star from '../assets/json/star.json';
import consulting from '../assets/json/consulting.json';
import world from '../assets/json/world.json';
import clock from '../assets/json/clock.json';
import LottieIcon from './LottieIcon';
import bg from '../assets/images/bg_why_choose_cross_docker.png';
import clsx from 'clsx';

const WHY_CHOOSE_US = [
  [
    {
      icon: gear,
      title: TRANSLATE_KEY.extremely_high_level_of_customization,
      description:
        TRANSLATE_KEY.extremely_high_level_of_customization_description,
    },
    {
      icon: clock,
      title: TRANSLATE_KEY.optimize_your_fleet,
      description: TRANSLATE_KEY.optimize_your_fleet_description,
    },
  ],
  [
    {
      icon: star,
      title: TRANSLATE_KEY.error_reduction,
      description: TRANSLATE_KEY.error_reduction_description,
    },
    {
      icon: support,
      title: TRANSLATE_KEY.improved_quality_of_life,
      description: TRANSLATE_KEY.improved_quality_of_life_description,
    },
  ],
  [
    {
      icon: world,
      title: TRANSLATE_KEY.seamless_connectivity,
      description: TRANSLATE_KEY.seamless_connectivity_description,
    },
    {
      icon: consulting,
      title: TRANSLATE_KEY.consulting_and_suggestions,
      description: TRANSLATE_KEY.consulting_and_suggestions_description,
    },
  ],
];

const WhyChooseUs = () => {
  const { t } = useTranslation();
  const swiperRef = useRef();
  const [isPrev, setIsPrev] = useState(false);
  const [isNext, setIsNext] = useState(true);

  const handleHideShowNavigation = (event) => {
    const { isBeginning, isEnd } = event;
    setIsPrev(!isBeginning);
    setIsNext(!isEnd);
  };

  return (
    <div
      className="bg-bgMain cd-section-container bg-no-repeat bg-cover bg-center"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <AnimationOnScroll
        className={`w-full max-w-7xl m-auto flex flex-col justify-center`}
        animateIn="animate__fadeInUp"
        offset={50}
        initiallyVisible={false}
        animatePreScroll={false}
      >
        <div className="w-full text-left">
          <h2 className="cd-text-title text-white mb-6 sm:mb-8 lg:mb-14">
            {t(TRANSLATE_KEY.why_choose_cross)}
            <span className="text-p500">{t(TRANSLATE_KEY.docker)}</span>
          </h2>
        </div>
        <div className="relative w-full">
          <Swiper
            onSlideChange={handleHideShowNavigation}
            autoplay={{ delay: 5000 }}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            className=""
            slidesPerView={1.1}
            speed={700}
            slidesPerGroup={1}
            navigation={false}
            modules={[Autoplay, Navigation]}
            allowTouchMove={true}
            spaceBetween={16}
            breakpoints={{
              992: {
                slidesPerView: 1.5,
                spaceBetween: 24,
              },
              1024: {
                slidesPerView: 1.5,
                spaceBetween: 48,
              },
              1440: {
                slidesPerView: 1.5,
                spaceBetween: 48,
              },
            }}
          >
            {WHY_CHOOSE_US.map((items, index) => {
              return (
                <SwiperSlide className="h-full" key={index}>
                  <div className="h-full flex flex-col lg:gap-16 md:gap-8 gap-x-4 gap-y-12">
                    {items.map(
                      (
                        {
                          icon,
                          title,
                          description,
                          extra,
                          className = 'w-24 h-24 md:w-36 md:h-36 lg:w-44 lg:h-44',
                        },
                        index,
                      ) => {
                        return (
                          <div
                            key={index}
                            className="w-full h-[300px] md:h-[200px] flex lg:gap-16 md:gap-8 gap-4 text-white flex items-center"
                          >
                            <LottieIcon
                              className={clsx(className)}
                              style={{
                                border: '1px solid white',
                                borderRadius: '5px',
                              }}
                              src={icon}
                            />
                            <div className="h-full flex justify-center flex-col">
                              <h3 className="text-2xl font-semibold mb-4">
                                {t(title)}
                              </h3>
                              <p>{t(description)}</p>
                              {extra && <p className="mt-1">{t(extra)}</p>}
                            </div>
                          </div>
                        );
                      },
                    )}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {isPrev && (
            <div
              className="absolute top-1/2 left-0 z-20 w-10 h-10"
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <ArrowLeft />
            </div>
          )}
          {isNext && (
            <div
              className="absolute top-1/2 right-0 w-10 h-10"
              onClick={() => swiperRef.current?.slideNext()}
            >
              <ArrowRight />
            </div>
          )}
        </div>
      </AnimationOnScroll>
    </div>
  );
};

export default WhyChooseUs;
