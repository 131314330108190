import React from 'react';
import Button from './button/Button';
import proof1 from '../assets/images/proof-1.png';
import longArrow from '../assets/control/long-arrow.svg';
import trackAndAccelerate from '../assets/json/track_and_accelerate.json';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { TRANSLATE_KEY } from '../languages';
import LottieIcon from './LottieIcon';
import emailSentJson from '../assets/json/email_sent.json';
import cityMarkerJson from '../assets/json/city_marker.json';
import electronicMailJson from '../assets/json/electronic_mail.json';
import bgImage from '../assets/images/bg-proof-of-delivery.png';
import { useTranslation } from 'react-i18next';

const LIST_FEATURES = [
  {
    title: TRANSLATE_KEY.track_and_trace,
  },
  {
    title: TRANSLATE_KEY.proactive_advice_by_emails,
  },
  {
    title: TRANSLATE_KEY.fleet_routes_optimization,
  },
  {
    title: TRANSLATE_KEY.electronic_proof_of_delivery,
  },
];

const AppSection = () => {
  const { t } = useTranslation();

  return (
    <div
      className="cd-section-container bg-left bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <AnimationOnScroll
        animateOnce
        className={`flex items-center`}
        animateIn="animate__fadeInUp"
        offset={100}
        initiallyVisible={false}
        animatePreScroll={false}
      >
        <div className="grid lg:grid-cols-2 max-lg:grid-col-1 max-w-7xl flex items-center">
          <div className="flex gap-10 flex-col">
            <div>
              <h1 className="text-black cd-text-title">
                {t(TRANSLATE_KEY.proof_of_delivery)}
              </h1>
              <h2 className="cd-text-title">
                <span className=" text-black200"> {t(TRANSLATE_KEY.app)}</span>
                <span className="text-p500"> {t(TRANSLATE_KEY.section)}</span>
              </h2>
            </div>
            <div className="flex flex-col  gap-1">
              {LIST_FEATURES.map(({ title }, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center gap-2 text-base font-semibold"
                  >
                    <img src={longArrow} alt={title} />
                    <p>{t(title)}</p>
                  </div>
                );
              })}
            </div>
            <p className=" text-black200">
              {t(TRANSLATE_KEY.app_section_description)}
            </p>
            <div className="max-lg:text-center mb-2">
              <Button type="secondary" text={t(TRANSLATE_KEY.download_now)} />
            </div>
          </div>
          <div className="relative flex items-start justify-center max-lg:mt-20">
            <div className="absolute top-[-60px] left-[14px] md:top-[-80px] md:left-[128px] lg:top-[-80px] lg:left-[92px]">
              <LottieIcon
                className="lg:w-[140px] md:w-[140px]  w-[120px]"
                src={emailSentJson}
              />
            </div>
            <div className="absolute top-[-72px] right-4 lg:top-[-100px] lg:right-10 md:top-[-100px] md:right-20">
              <LottieIcon
                className="lg:w-[180px] md:w-[180px] w-[120px]"
                src={trackAndAccelerate}
              />
            </div>
            <div className="absolute bottom-[-20px] left-[4px] lg:bottom-[-24px] lg:left-[72px] md:bottom-[-28px] md:left-[108px]">
              <LottieIcon
                className="lg:w-[140px] md:w-[140px] w-[100px]"
                src={cityMarkerJson}
              />
            </div>
            <div className="absolute bottom-[-32px] right-[-30px] lg:bottom-[-48px] lg:right-[-60px] md:bottom-[-48px] md:right-[-60px]">
              <LottieIcon
                className="lg:w-[380px] md:w-[380px] w-[240px]"
                src={electronicMailJson}
              />
            </div>
            <img className="w-[500px] h-auto" src={proof1} alt="" />
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

export default AppSection;
