import { useEffect, useState } from 'react';

const BREAK_POINT = 1024;
const useWindowResize = () => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const isDesktop = size.width >= BREAK_POINT;

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isDesktop, size, BREAK_POINT };
};

export default useWindowResize;
