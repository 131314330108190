import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './locales/en';
// import viTranslation from './locales/vi';
import itTranslation from './locales/it';

const TRANSLATE_KEY = {};
Object.keys(enTranslation).forEach((key) => (TRANSLATE_KEY[key] = key));

const SUPPORTED_LANGUAGE = [
  {
    code: 'en',
  },
  {
    code: 'it',
  },
];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      it: {
        translation: itTranslation,
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    nsSeparator: ':::',
    keySeparator: '::',
  });

export { TRANSLATE_KEY, SUPPORTED_LANGUAGE };
export default i18n;
