import { useState, useEffect, useRef } from 'react';

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState('up');
  let prevScrollPos = useRef(window.scrollY);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    if (currentScrollPos === 0) {
      setScrollDirection('up');
    } else {
      if (currentScrollPos > prevScrollPos.current) {
        setScrollDirection('down');
      } else if (currentScrollPos < prevScrollPos.current) {
        setScrollDirection('up');
      }
    }
    prevScrollPos.current = currentScrollPos;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollDirection;
};

export default useScrollDirection;
