import { TRANSLATE_KEY } from '../languages';

export const IMAGES = {
  no_image: require('../assets/images/image_icon.jpg'),
  gear: require('../assets/images/gear.gif'),
  john_smith: require('../assets/images/john_smith.png'),
  sarah_johnson: require('../assets/images/sarah_johnson.png'),
  mark_williams: require('../assets/images/mark_williams.png'),
  emily_davis: require('../assets/images/emily_davis.png'),
  david_anderson: require('../assets/images/david_anderson.png'),
  lisa_miller: require('../assets/images/lisa_miller.png'),
};

export const SECTION_ID = {
  HERO: 'hero-section',
  FEATURES: 'features-section',
  WHY_CHOOSE_US: 'why-choose-us-section',
  APP_SECTION: 'app-section',
  FEEDBACK: 'feedback-section',
  CONTACT_US: 'contact-us-section',
};

export const NAVBAR = [
  {
    title: TRANSLATE_KEY.home,
    sectionId: SECTION_ID.HERO,
  },
  {
    title: TRANSLATE_KEY.features,
    sectionId: SECTION_ID.FEATURES,
  },
  {
    title: TRANSLATE_KEY.feedback,
    sectionId: SECTION_ID.FEEDBACK,
  },
  {
    title: TRANSLATE_KEY.contact_us,
    sectionId: SECTION_ID.CONTACT_US,
  },
];

export const ROUTE = {
  HOME: '/',
};
