const en = {
  home: 'Home',
  features: 'Features',
  feedback: 'Feedback',
  contact_us: 'Contact Us',
  welcome_to: 'Welcome to',
  home_description_line1:
    'The innovative working method that will save you a lot of time',
  home_description_line2:
    'and reduce stress in managing your shipment deliveries',
  streamline_your_logistics_operations: 'Streamline Your Logistics Operations',
  streamline_your_logistics_operations_description:
    'Efficient automation, real-time tracking, and cost-effective optimization of logistics processes',
  reduce_stress_for_your_team: 'Reduce Stress for Your Team',
  reduce_stress_for_your_team_description:
    'Efficient automation and optimized workflows',
  minimize_errors_and_mistakes: 'Minimize Errors and Mistakes',
  minimize_errors_and_mistakes_description:
    'Automate and validate to minimize costly errors and operational mistakes',
  eliminate_paper_usage: 'Eliminate Paper Usage',
  eliminate_paper_usage_description:
    'Through digital storage, enhancing efficiency, and environmental responsibility',
  enhance_customer_satisfaction: 'Enhance Customer Satisfaction',
  enhance_customer_satisfaction_description:
    'With precise deliveries, speedy service, and error-free operations.',
  extremely_high_level_of_customization:
    'Extremely high level of customization',
  extremely_high_level_of_customization_description:
    "The founding principle of CrossDocker is to be highly customizable to the individual client's needs and way of working, while still providing them with the knowledge and features acquired over years from various industry entities.",
  optimize_your_fleet: 'Optimize Your Fleet',
  optimize_your_fleet_description:
    'Maximize the efficiency of your truck fleet, whether owned or outsourced. CrossDocker ensures your deliveries are on time and cost-effective.',
  error_reduction: 'Error Reduction',
  error_reduction_description:
    'Streamlined processes mean fewer errors. Keep your customers satisfied with accurate deliveries and pickups.',
  improved_quality_of_life: 'Improved Quality of Life',
  improved_quality_of_life_description:
    'Happy, stress-free employees result in higher job satisfaction and better work-life balance.',

  seamless_connectivity: 'Seamless Connectivity',
  seamless_connectivity_description:
    'CrossDocker seamlessly integrates with external software used by carriers, customers, and shipping networks for efficient communication.',
  seamless_connectivity_description_extra:
    '[Call-to-Action Button: "Learn More" or "Request a Demo"]',

  consulting_and_suggestions: 'Consulting and suggestions',
  consulting_and_suggestions_description:
    'Our logistics experts, with daily engagement across the supply chain, provide tailored consulting services. Beyond CrossDocker, we offer guidance on office operations, warehousing, trucking, and optimizing customer and supplier relationships, including rate negotiations and usage terms.',

  track_and_trace: 'Track and Trace',
  proactive_advice_by_emails: 'Proactive advice by emails',
  fleet_routes_optimization: 'Fleet routes optimization',
  electronic_proof_of_delivery: 'Electronic Proof of Delivery (ePOD) App',
  track_and_trace_it: 'Track and Trace it',
  app_section_description:
    'Track and confirm deliveries in real-time with our electronic Proof of Delivery app for Android and iOS devices. Ensure transparency and customer satisfaction with our user-friendly app.',

  i_love_the_customization_options:
    "I LOVE the customization options! This is more than I thought would be possible right now. I really like the ability to customize the colors and background so it's seamless with my branding and I can share it in place of my website when they want to start hiring right away.",
  logistics_manager: 'Logistics Manager',
  logistics_manager_comment:
    "Crossdocker has transformed our shipping operations. It's user-friendly and has significantly reduced errors in our deliveries, and above all, we have more time for ourselves that no longer has to be spent on useless and repetitive operations",
  operations_director: 'Operations Director',
  operations_director_comment:
    'The customization options of Crossdocker are outstanding. It feels like it was designed specifically for our unique needs, and the optimization of our fleet has resulted in cost savings we never thought possible.',
  warehouse_manager: 'Warehouse Manager',
  warehouse_manager_comment:
    "I can't express how much Crossdocker has improved the quality of life for our team. Our employees are happier and more efficient, and they can work from anywhere. It's a game-changer",
  customer_service_representative: 'Customer Service Representative',
  customer_service_representative_comment:
    "The seamless connectivity of Crossdocker has simplified our communication with carriers and customers. It's like a well-oiled machine now, and our customers are noticing the improvement in our service.",
  supply_chain_director: 'Supply Chain Director',
  supply_chain_director_comment:
    "The consulting services provided by Crossdocker have been invaluable. Their experts have helped us identify solutions beyond the software itself, from optimizing our office operations to negotiating better rates with suppliers. It's a partnership that go",
  operations_manager: 'Operations Manager',
  operations_manager_comment:
    "Crossdocker has made our supply chain more efficient and reduced our costs. It's an investment that pays off, and the support from their team is top-notch. We highly recommend it",

  crossdocker_is_a_solution_by_bluesky_srl:
    'CrossDocker is a solution by Bluesky Srl',
  email: 'Email',
  phone: 'Phone',
  location: 'Location',
  location_detail: 'Via Campestrin 4, Farra di Soligo,TV, Italy',

  request_a_demo: 'Request a demo',
  get_started: 'Get started',
  with: 'With',
  cross_docker: 'CrossDocker',
  you_can: 'you can',
  cross: 'Cross',
  docker_is_here_to_revolutionize_the_way_you_manage:
    'Docker is here to revolutionize the way you manage',
  your_shipments_pickups_and_deliveries:
    'your shipments, pickups, and deliveries.',
  why_choose_cross: 'Why choose Cross',
  docker: 'Docker',
  send_contact_successfully:
    'Thank you for contacting us! We will contact you as soon as possible!',
  send: 'Send',

  name: 'Name',
  phone_number: 'Phone number',
  address: 'Address',
  ready_to: 'Ready to',
  simplify_your_work: 'Simplify Your work?',
  got_questions_or_ready_to_get_started:
    'Got questions or ready to get started with CrossDocker? Contact us today, and our team will be happy to assist you.',
  what_our_customers: 'What Our Customers',
  reviewed: 'reviewed',

  the_input_is_not_valid_email: 'The input is not valid Email!',
  please_input_your_name: 'Please input your name!',
  please_input_your_email: 'Please input your Email!',
  please_input_your_phone_number: 'Please input your phone number!',
  please_input_your_address: 'Please input your address!',

  proof_of_delivery: 'Proof of delivery',
  app: 'App',
  section: 'Section',
  download_now: 'Download Now',
};

export default en;
