import HomePage from './pages/HomePage';
import { store } from './store';
import { Provider } from 'react-redux';
import './languages';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ROUTE } from './constants';

function App() {
  return (
    <Router>
      <Provider store={store}>
        <Routes>
          <Route
            exact={false}
            path={`${ROUTE.HOME}`}
            element={<HomePage />}
          ></Route>
        </Routes>
      </Provider>
    </Router>
  );
}

export default App;
