import { Spin } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const buttonClasses = {
  base: '',
  primary: {
    container:
      'border-p500 bg-white border-2 border-p500 hover:border-transparent ',
    effect: 'bg-p500',
    text: 'text-p500 group-hover:text-white',
  },
  secondary: {
    container: 'bg-p500 border-2 border-p500 ',
    effect: 'bg-white ',
    text: 'group-hover:text-p500 text-white',
  },
};

const Button = ({
  type = 'primary',
  text,
  className = '',
  loading = false,
  ...rest
}) => {
  return (
    <div className={clsx('relative rounded-3xl overflow-hidden')}>
      <button
        className={clsx(
          className,
          `${buttonClasses[type].container} group relative h-12 w-48 overflow-hidden rounded-3xl text-lg shadow`,
        )}
        {...rest}
      >
        <div
          className={`${buttonClasses[type].effect} absolute inset-0 w-0 transition-all duration-[400ms] ease-in-out group-hover:w-full rounded-3xl`}
        ></div>

        <span
          className={`${buttonClasses[type].text} relative font-semibold flex justify-center items-center`}
        >
          {text}
          {loading && (
            <Spin
              className="ml-4"
              indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />}
            />
          )}
        </span>
      </button>
    </div>
  );
};

export default Button;
