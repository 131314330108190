const it = {
  home: 'Home',
  features: 'Caratteristiche',
  feedback: 'Feedback',
  contact_us: 'Contattaci',
  welcome_to: 'Benvenuti su',
  home_description_line1:
    'Il metodo di lavoro innovativo che ti fa risparmiare tempo',
  home_description_line2:
    'e ridurre lo stress nella gestione delle spedizioni e del primo e ultimo miglio',

  streamline_your_logistics_operations:
    'Ottimizza le tue operazioni di Crossdocking',
  streamline_your_logistics_operations_description:
    'Automazione efficiente, tracciamento in tempo reale efficientamento dei processi logistici e di creazione dei viaggi di Ritiro e Consegna',
  reduce_stress_for_your_team: 'Riduci lo stress per il tuo team',
  reduce_stress_for_your_team_description:
    'Automatizza i processi di acquisizione, gestione e inoltro ordini, riducendo lo stress a impiegati, magazzinieri e personale viaggiante',
  minimize_errors_and_mistakes: 'Riduci gli errori e ottimizza i tempi',
  minimize_errors_and_mistakes_description:
    'Un potente sistema di convalida delle informazioni per ridurre gli errori umani che generano costi ingenti e perdite di tempo inutili',
  eliminate_paper_usage: "Elimina l'uso della carta",
  eliminate_paper_usage_description:
    "Attraverso la gestione e conservazione digitale elimina l'abuso di carta e post-it dalle tue scrivanie, e aiuta l'ambiente riducendo lo spreco di carta",

  enhance_customer_satisfaction: 'Aumenta la soddisfazione del cliente',
  enhance_customer_satisfaction_description:
    'Con consegne precise, servizio veloce e operazioni prive di errori',

  extremely_high_level_of_customization:
    'Livello estremamente alto di personalizzazione',
  extremely_high_level_of_customization_description:
    'Il principio fondante di CrossDocker è essere altamente personalizzabile in base alle esigenze individuali del cliente, introducendo nuove ed utili dinamiche e funzionalità acquisite nel corso degli anni in aziende specializzate in trasporto e logistica.',
  optimize_your_fleet: 'Ottimizza la tua flotta',
  optimize_your_fleet_description:
    "Massimizza l'efficienza della tua flotta di camion, che siano di tua proprietà o esternalizzati. CrossDocker garantisce che le tue consegne siano puntuali ed economiche.",
  error_reduction: 'Riduzione degli errori',
  error_reduction_description:
    'Processi ottimizzati a automatizzati significano meno errori. Mantieni i tuoi clienti soddisfatti del tuo servizio con consegne e ritiri accurati.',
  improved_quality_of_life: 'Miglioramento della qualità della vita',
  improved_quality_of_life_description:
    'I dipendenti felici e senza stress hanno una maggiore soddisfazione professionale e un miglior equilibrio tra vita professionale e privata. Questo porta a un miglior ambiente di lavoro e si traduce automaticamente in una migliore risposta ai tuoi Clienti.',

  seamless_connectivity: 'Connessione senza soluzione di continuità',
  seamless_connectivity_description:
    'CrossDocker si integra in modo perfetto con il software esterno utilizzato dai Corrispondenti, dai clienti, dai Vettori e dai Network di spedizione.',

  consulting_and_suggestions: 'Consulenza e suggerimenti',
  consulting_and_suggestions_description:
    "I nostri esperti di logistica, impegnati quotidianamente lungo la supply chain, forniscono servizi di consulenza personalizzati. Grazie all'esperienza maturata con diverse realtà del settore, diamo orientamenti sulle operazioni di ufficio, logistica, trasporto su camion e sulla gestione dei rapporti operativi ed economici con clienti e fornitori.",

  track_and_trace: 'Tracciabilità',
  proactive_advice_by_emails: 'Consigli proattivi tramite email',
  fleet_routes_optimization: 'Ottimizzazione dei percorsi della flotta',
  electronic_proof_of_delivery:
    "App per l'esitazione e la prova di consegna elettronica (ePOD)",
  track_and_trace_it: 'Tracciabilità',
  app_section_description:
    'Traccia e conferma le consegne in tempo reale con la nostra app per dispositivi Android e iOS.  Assicura trasparenza e soddisfazione ai tuoi clienti con la nostra versatile app.',

  i_love_the_customization_options:
    'ADORO le opzioni di personalizzazione! Questo va oltre ciò che pensavo fosse possibile al momento. Mi piace molto la possibilità di personalizzare i colori e lo sfondo in modo da integrarsi con il mio marchio e posso condividerlo al posto del mio sito web quando vogliono iniziare a assumere subito.',

  logistics_manager: 'Responsabile della logistica',
  logistics_manager_comment:
    'CrossDocker ha trasformato le nostre operazioni di spedizione. È facile da usare e ha ridotto significativamente gli errori nelle nostre consegne, e soprattutto abbiamo più tempo per noi stessi che non deve più essere speso in operazioni inutili e ripetitive.',
  operations_director: 'Direttore operativo',
  operations_director_comment:
    "Le opzioni di personalizzazione di CrossDocker sono eccezionali. Sembra progettato appositamente per le nostre esigenze e l'ottimizzazione della nostra flotta ha portato a dei risparmi di tempo e costi che non credevamo possibili.",
  warehouse_manager: 'Responsabile del magazzino',
  warehouse_manager_comment:
    'Non posso esprimere quanto CrossDocker abbia migliorato la qualità della vita del nostro team. I nostri dipendenti sono più felici e più efficienti, e possono lavorare ovunque. È una svolta',
  customer_service_representative: 'Rappresentante del servizio clienti',
  customer_service_representative_comment:
    'La connettività senza soluzione di continuità di CrossDocker ha semplificato la nostra comunicazione con i vettori e i clienti. Ora è come una macchina ben oliata e i nostri clienti hanno notato il miglioramento del nostro servizio.',
  supply_chain_director: 'Direttore della catena di approvvigionamento',
  supply_chain_director_comment:
    "I servizi di consulenza forniti sono stati preziosi. Ci hanno aiutato a individuare soluzioni al di là del software stesso, dall'ottimizzazione delle operazioni in ufficio alla trattativa di tariffe e servizi migliori con i fornitori. È una partnership che ci ha portato nuove prospettive.",
  operations_manager: 'Responsabile delle operazioni',
  operations_manager_comment:
    'Direttore di Filiale CrossDocker ha reso la nostra gestione più efficiente e ha ridotto i nostri costi. È un investimento che si ripaga da subito, e il supporto',

  crossdocker_is_a_solution_by_bluesky_srl:
    'CrossDocker è una soluzione di Bluesky Srl',
  email: 'Email',
  phone: 'Telefono',
  location: 'Posizione',
  location_detail: 'Via Campestrin 4, Farra di Soligo, TV, Italia',

  request_a_demo: 'Richiedi una demo',
  get_started: 'Inizia',
  with: 'Con',
  cross_docker: 'CrossDocker',
  you_can: 'puoi',
  cross: 'Cross',
  docker_is_here_to_revolutionize_the_way_you_manage:
    'Docker è qui per rivoluzionare il modo in cui gestisci',
  your_shipments_pickups_and_deliveries:
    'le tue spedizioni, ritiri e consegne.',
  why_choose_cross: 'Perché scegliere Cross',
  docker: 'Docker',
  send_contact_successfully:
    'Grazie per averci contattato! Ti contatteremo al più presto!',
  send: 'Invia',

  name: 'Nome',
  phone_number: 'Numero di telefono',
  address: 'Indirizzo',
  ready_to: 'Pronto a',
  simplify_your_work: 'semplificare il tuo lavoro?',
  got_questions_or_ready_to_get_started:
    'Hai domande o sei pronto a iniziare con CrossDocker? Contattaci oggi e il nostro team sarà lieto di assisterti.',
  what_our_customers: 'Cosa dicono i nostri clienti',
  reviewed: 'recensito',

  the_input_is_not_valid_email: "L'input non è un indirizzo email valido!",
  please_input_your_name: 'Per favore inserisci il tuo nome!',
  please_input_your_email: 'Inserisci la tua email!',
  please_input_your_phone_number: 'Inserisci il tuo numero di telefono!',
  please_input_your_address: 'Inserisci il tuo indirizzo!',

  proof_of_delivery: 'Prova di consegna',
  app: 'App',
  section: 'Sezione',
  download_now: 'Scarica ora',
};

export default it;
