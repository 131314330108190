import React, { useEffect, useRef } from 'react';
import Navbar from '../components/Nav';
import Hero from '../components/Hero';
import Features from '../components/Features';
import WhyChooseUs from '../components/WhyChooseUs';
import AppSection from '../components/AppSection';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import Feedback from '../components/Feedback';
import { SECTION_ID } from '../constants';
import { useLocation } from 'react-router';
import goToElement from '../helpers/goToElement';

const HomePage = () => {
  const location = useLocation();
  const { hash } = location;
  const containerRef = useRef(null);

  useEffect(() => {
    const targetId = (hash || SECTION_ID.HERO).replaceAll('#', '');
    goToElement(targetId);
  }, [hash]);

  return (
    <div
      ref={containerRef}
      className="relative text-[13px] sm:text-[14px] md:text-[16px]"
    >
      <Navbar />
      <section id={SECTION_ID.HERO}>
        <Hero />
      </section>
      <section id={SECTION_ID.FEATURES}>
        <Features />
      </section>
      <section id={SECTION_ID.WHY_CHOOSE_US}>
        <WhyChooseUs />
      </section>
      <section id={SECTION_ID.APP_SECTION}>
        <AppSection />
      </section>
      <section id={SECTION_ID.FEEDBACK}>
        <Feedback />
      </section>
      <section id={SECTION_ID.CONTACT_US}>
        <ContactUs />
      </section>
      <Footer />
    </div>
  );
};

export default HomePage;
