import React, { useRef, useState } from 'react';

import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import iconDisablePrev from '../assets/control/icon-disable-reviewed.png';
import iconNext from '../assets/control/icon-next-reviewed.svg';
import { TRANSLATE_KEY } from '../languages';
import { useTranslation } from 'react-i18next';
import { IMAGES } from '../constants';

const CUSTOMERS_REVIEWED = [
  {
    icon: IMAGES.john_smith,
    customer: 'Sandro',
    position: TRANSLATE_KEY.logistics_manager,
    comment: TRANSLATE_KEY.logistics_manager_comment,
  },
  {
    icon: IMAGES.sarah_johnson,
    customer: 'Adriana',
    position: TRANSLATE_KEY.operations_director,
    comment: TRANSLATE_KEY.operations_director_comment,
  },
  {
    icon: IMAGES.mark_williams,
    customer: 'Francesco',
    position: TRANSLATE_KEY.warehouse_manager,
    comment: TRANSLATE_KEY.warehouse_manager_comment,
  },
  {
    icon: IMAGES.emily_davis,
    customer: 'Matteo',
    position: TRANSLATE_KEY.customer_service_representative,
    comment: TRANSLATE_KEY.customer_service_representative_comment,
  },
  {
    icon: IMAGES.david_anderson,
    customer: 'Luca',
    position: TRANSLATE_KEY.supply_chain_director,
    comment: TRANSLATE_KEY.supply_chain_director_comment,
  },
  {
    icon: IMAGES.lisa_miller,
    customer: 'Sara',
    position: TRANSLATE_KEY.operations_manager,
    comment: TRANSLATE_KEY.operations_manager_comment,
  },
];

const Feedback = () => {
  const swiperRef = useRef();
  const [isPrev, setIsPrev] = useState(false);
  const [isNext, setIsNext] = useState(true);
  const { t } = useTranslation();

  const handleHideShowNavigation = (event) => {
    const { isBeginning, isEnd } = event;
    setIsPrev(!isBeginning);
    setIsNext(!isEnd);
  };

  return (
    <div className="bg-p50 h-screen pt-1/2 px-5 lg:px-10">
      <div className="max-w-7xl m-auto h-full flex flex-col justify-center">
        <AnimationOnScroll
          className={``}
          animateIn="animate__fadeInUp"
          offset={100}
          initiallyVisible={false}
          animatePreScroll={false}
        >
          <div className="mb-9">
            <h1 className="cd-text-title text-black">
              {t(TRANSLATE_KEY.what_our_customers)}
            </h1>
            <h2 className="cd-text-title">
              <span className="text-p500"> {t(TRANSLATE_KEY.reviewed)}</span>
            </h2>
          </div>

          <div className="relative ">
            <Swiper
              // onSlideNextTransitionStart={() => setHide(true)}
              // onSlidePrevTransitionStart={() => setHide(false)}
              onSlideChange={(e) => handleHideShowNavigation(e)}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              autoplay={{ delay: 5000 }}
              className=""
              slidesPerView={1.2}
              speed={700}
              slidesPerGroup={1}
              navigation={false}
              modules={[Autoplay, Navigation]}
              allowTouchMove={true}
              spaceBetween={12}
              breakpoints={{
                568: {
                  slidesPerView: 1.2,
                  slidesPerGroup: 1,
                  spaceBetween: 12,
                },
                768: {
                  slidesPerView: 2.2,
                  slidesPerGroup: 2,
                  spaceBetween: 24,
                },
                1024: {
                  slidesPerView: 2.5,
                  slidesPerGroup: 2,
                  spaceBetween: 24,
                },
              }}
            >
              {CUSTOMERS_REVIEWED.map(
                ({ customer, position, comment, icon }, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="bg-white rounded-md h-72 p-4 md:h-80 md:p-6 lg:h-70 flex flex-col justify-between">
                        <p className="mb-4">{t(comment)}</p>
                        <div className="flex items-center">
                          <div className="w-16 h-16 rounded-full">
                            <img src={icon} alt="" />
                          </div>
                          <div className="flex flex-col ml-4 h-16">
                            <span className="font-semibold">{customer}</span>
                            <span className=" text-sm text-black200">
                              {t(position)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                },
              )}
            </Swiper>
            {isPrev ? (
              <div
                className="absolute -top-16 right-12 z-20 w-8 h-8 cursor-pointer"
                onClick={() => swiperRef.current?.slidePrev()}
              >
                <img src={iconNext} alt="" className=" rotate-180 w-7" />
              </div>
            ) : (
              <div className="absolute -top-16 right-12 z-20 w-8 h-8">
                <img src={iconDisablePrev} alt="" className=" w-7" />
              </div>
            )}
            {isNext ? (
              <div
                className="absolute -top-16 right-0 w-8 h-8 cursor-pointer"
                onClick={() => swiperRef.current?.slideNext()}
              >
                <img src={iconNext} alt="" className="w-7" />
              </div>
            ) : (
              <div
                className="absolute -top-16 right-0 w-8 h-8"
                onClick={() => swiperRef.current?.slideNext()}
              >
                <img src={iconDisablePrev} alt="" className="rotate-180 w-7" />
              </div>
            )}
          </div>
        </AnimationOnScroll>
      </div>
    </div>
  );
};

export default Feedback;
