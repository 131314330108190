const goToElement = (targetId) => {
  const target = document.getElementById(targetId);
  if (target) {
    const offsetTop = target.offsetTop;
    window.scroll({
      top: offsetTop,
      behavior: 'smooth',
    });
  }
};
export default goToElement;
