import React, { useEffect, useRef, useState } from 'react';
import bgWordConnect from '../assets/images/bg-world-connect.png';
import Typewriter from 'typewriter-effect';
import Button from './button/Button';
import { TRANSLATE_KEY } from '../languages';
import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import mapIcon from '../assets/images/map-icon.svg';
import bagIcon from '../assets/images/bag.svg';

const FEATURES = [
  {
    title: TRANSLATE_KEY.streamline_your_logistics_operations,
    description: TRANSLATE_KEY.streamline_your_logistics_operations_description,
  },
  {
    title: TRANSLATE_KEY.reduce_stress_for_your_team,
    description: TRANSLATE_KEY.reduce_stress_for_your_team_description,
  },
  {
    title: TRANSLATE_KEY.minimize_errors_and_mistakes,
    description: TRANSLATE_KEY.minimize_errors_and_mistakes_description,
  },
  {
    title: TRANSLATE_KEY.eliminate_paper_usage,
    description: TRANSLATE_KEY.eliminate_paper_usage_description,
  },
  {
    title: TRANSLATE_KEY.enhance_customer_satisfaction,
    description: TRANSLATE_KEY.enhance_customer_satisfaction_description,
  },
];

const Features = () => {
  const { t } = useTranslation();
  const animateRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.5, // When 50% of the target is in view
    };
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        return;
      }
      setIsVisible(false);
    }, options);
    observer.observe(animateRef.current);
  }, []);

  return (
    <div
      className="cd-section-container bg-center bg-cover bg-no-repeat lg:py-5 "
      style={{ backgroundImage: `url(${bgWordConnect})` }}
    >
      <div className="max-w-7xl mx-auto " ref={animateRef}>
        <div className="relative">
          <h1 className="cd-text-title text-center text-white">
            <AnimationOnScroll
              className="mb-1"
              // animateOnce
              duration={2}
              animateIn="animate__animated animate__bounceInUp"
            >
              <h2 className="cd-text-title">{t(TRANSLATE_KEY.with)}</h2>
            </AnimationOnScroll>
            <AnimationOnScroll
              className="mb-1"
              // animateOnce
              duration={2}
              animateIn="animate__animated animate__bounceInUp"
              delay={100}
            >
              <h2 className="cd-text-title">{t(TRANSLATE_KEY.cross_docker)}</h2>
            </AnimationOnScroll>
            <AnimationOnScroll
              className="mb-1"
              // animateOnce
              duration={2}
              animateIn="animate__animated animate__bounceInUp"
              delay={100}
            >
              <h2 className="cd-text-title">{t(TRANSLATE_KEY.you_can)}</h2>
            </AnimationOnScroll>
          </h1>
          <AnimationOnScroll
            // animateOnce
            delay={200}
            duration={2}
            animateIn="animate__animated animate__fadeIn"
          >
            <div className="px-9 py-4 w-full md:w-[72%] lg:w-[72%] m-auto min-h-[80px] bg-white text-base text-black200 text-center rounded-2xl mt-7 font-semibold type-writer-features relative">
              <div className="absolute top-0 left-1/2 w-0 h-0 -translate-y-full -translate-x-1/2 border-l-[25px] border-l-transparent border-b-[25px] border-b-white border-r-[25px] border-r-transparent"></div>
              {isVisible && (
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(
                        `<strong>${t(
                          TRANSLATE_KEY.cross,
                        )}</strong><span className='docker-text'>${t(
                          TRANSLATE_KEY.docker_is_here_to_revolutionize_the_way_you_manage,
                        )}
            </span> </br>
            <strong className='text-black100'>
              ${t(TRANSLATE_KEY.your_shipments_pickups_and_deliveries)}
            </strong>`,
                      )
                      .pauseFor(2500)
                      .start();
                  }}
                  options={{
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 10,
                    delay: 10,
                  }}
                />
              )}
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__animated animate__bounceInUp"
            className="absolute top-4 left:4 md:left-10 lg:w-36 md:w-24 w-16"
          >
            <img src={bagIcon} alt="bag" />
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__animated animate__bounceInUp"
            className="absolute md:top-28 md:right-0 lg:w-36 md:w-24 w-16 top-16 right-2"
          >
            <img src={mapIcon} alt="point" />
          </AnimationOnScroll>
        </div>

        <AnimationOnScroll
          delay={200}
          duration={2}
          animateIn="animate__animated animate__fadeIn"
        >
          <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 max-lg:mb-1 lg:gap-2 max-lg:gap-4 mt-12">
            {FEATURES.map(({ title, description }, index) => {
              return (
                <div key={index}>
                  <h3 className="text-white text-base font-bold md:mb-1 md:min-h-[3rem]">
                    {t(title)}
                  </h3>
                  <p className=" text-white">{t(description)}</p>
                </div>
              );
            })}
          </div>
        </AnimationOnScroll>

        <div className="flex justify-center items-center mt-6">
          <Button type="primary" text={t(TRANSLATE_KEY.request_a_demo)} />
        </div>
      </div>
    </div>
  );
};

export default Features;
